/* Reset some default styles to ensure consistency */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Use a modern sans-serif font for the whole app */
body {
  font-family: 'Roboto', 'Arial', sans-serif;
  background: #f7f7f7;
}

/* Main app container styles */
.App {
  color: #fff;
  max-width: 800px;
  margin: 0px auto;
  padding: 20px;
  background: #000;
  /* border-radius: 10px; */ /* This line should be removed or commented out */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
}

/* Header styles */
.App-header {
  background: #000;
  padding: 1.5px 1px .2px;
  color: white;
  font-size: 1em;
  font-family: 'Futura Medium', sans-serif;
  border-radius: 10px 10px 0 0;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.title-container {
  background: #000;
  color: white;
  text-align: center;
  width: 100%; /* Take up the full width to ensure centering */
  border-radius: 10px 10px 0 0;
  font-size: 3.42em;
  font-family: 'Futura Medium', sans-serif;
  padding: 10px 20px;
  font-weight: bold; 
}

.title-container a {
  color: white;          /* Set the text color to white */
  text-decoration: none; /* Remove the underline */
}

.title-container a:hover {
  color: white;          /* Keep the text color white on hover */
  text-decoration: none; /* Ensure no underline on hover */
}

.title-container a:visited {
  color: white;          /* Keep the text color white after the link has been clicked */
}

/* Navigation button styles */
.navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px; /* Adjust the space between buttons */
  margin-top: 25px; /* Increase the top margin to lower the buttons */
  margin-bottom: 10px; /* Adjust this value to control space below the buttons */
}

.button, .navigation-buttons a, .navigation-buttons button {
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 6px 10px;
  margin: 0 5px;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  font-size: 0.8em;
  transition: background-color 0.3s;
}

/* Hover effect for all buttons */
.button:hover, .navigation-buttons a:hover, .navigation-buttons button:hover {
  background: #45a049;
}

/* Container styles for messages */
.message-container {
  margin-top: 17.6px;
}

/* Individual message styles */
.message {
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Styles for media inside messages */
img, video {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
  display: block; /* This changes the default inline nature of images to block, which is necessary for margins to work */
  margin-left: auto; /* Center the media horizontally */
  margin-right: auto; /* Center the media horizontally */
}

/* Styles for Twitter embeds */
.twitter-embed {
  margin-top: 10px;
  max-width: 500px; /* Adjust this width as needed */
  margin: 10px auto; /* Top and bottom margins can be any value, left and right auto centers the div */
}

/* Group name styles inside a message */
.message-group {
  font-size: 1.4em;
  color: #007bff;
  margin-bottom: 15px;
  font-weight: bold;
}

/* Styles for the message content */
.message-content {
  font-size: 1em;
  line-height: 1.7;
  color: #555;
  margin-bottom: 15px;
  white-space: normal; /* Allows text to wrap */
  overflow-wrap: break-word; /* Ensures long words do not overflow the container */
}

/* Timestamp styles */
.message-timestamp {
  font-size: 0.85em;
  color: #999;
  text-align: right;
  margin-top: 10px;
}

/* Loading message styles */
.loading-message {
  color: #fff;
  text-align: center;
  margin: 20px 0;
}

/* General page styles for Explore and My Pinnages */
.explore-page, .my-pinnages-page {
  padding: 20px;
  background-color: #f2f2f2;
  margin: 20px 0;
  border-radius: 8px;
}

.my-pinnages-page {
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Add any other styles from .message that you want to apply */
}

/* Form styles inside My Pinnages */
.my-pinnages-page form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Input styles */
.my-pinnages-page form input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Button styles inside forms */
.my-pinnages-page form button {
  background: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Hover effect for form buttons */
.my-pinnages-page form button:hover {
  background: #2980b9;
}

/* Styles for links inside the My Pinnages page */
.my-pinnages-page a {
  color: #3498db;
}

/* Hover effect for links */
.my-pinnages-page a:hover {
  text-decoration: underline;
}

/* If you have specific styles for headers and paragraphs in messages, apply them here */
.my-pinnages-page h2 {
  /* Styles to match .message-group */
  color: #007bff;
  font-weight: bold;
  /* Other styles */
}

.my-pinnages-page p, .my-pinnages-page li {
  /* Styles to match .message-content */
  color: #555;
  line-height: 1.7;
  /* Other styles */
}

.aggregator-text {
  color: white; /* White color as the buttons */
  text-align: center; /* Center the text */
  font-size: 1.2em; /* Same size as the button text */
  font-family: 'Roboto', 'Arial', sans-serif; /* Same font as the buttons */
  margin: 5px 0; /* Spacing above and below the text */
}

.spacer {
  height: 20px; /* Adjust the height as needed */
}


