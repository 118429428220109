/* Explore.css */
.explore-container {
  max-width: 800px;
  margin-top: 17.6px;
  margin: 20px auto; /* Adjusted to match the App container margin */
  padding: 20px;  
  background: white; /* Match the App background */
  border-radius: 10px; /* Match the App border radius */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05); /* Match the App shadow */
}

.explore-header {
  text-align: left;
  margin-bottom: 20px;
  font-size: 1.56em; /* Adjust if you want to match the App title size */
  color: #007bff; /* Preserved from your provided style */
  font-family: 'Futura Medium', sans-serif; /* Match the App font family if needed */
}

.groups-list {
  list-style-type: none;
  padding: 0;
}

.group-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Matched to App's message shadow */
  margin-bottom: 20px; /* Consistent with App message spacing */
  padding: 20px; /* Consistent padding with App's message */
  transition: transform 0.2s;
}

.group-card:hover {
  transform: translateY(-5px); /* Preserved from your provided style */
}

.group-link {
  text-decoration: none;
  color: #333; /* Preserved from your provided style */
  font-weight: bold;
  display: block; /* Make the link fill the card for easier clicking */
  font-size: 1em; /* Adjust if you want to match the App link size */
}

.group-link:hover {
  color: #007bff; /* Preserved from your provided style */
}

.loading-message {
  text-align: center;
  margin: 20px 0;
  font-family: 'Roboto', 'Arial', sans-serif; /* Match the App body font family if needed */
  color: #555; /* Match the App text color */
}

