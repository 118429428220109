/* GroupDetails.css - Match styles from App.css where applicable */

.message-container {
  margin-top: 17.6px;
}

.message {
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.media {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}

.message-group {
  font-size: 1.4em;
  color: white;
  margin-bottom: 15px;
  font-weight: bold;
}

.message-content {
  font-size: 1em;
  line-height: 1.7;
  color: #555;
  margin-bottom: 15px;
  white-space: normal;
  overflow-wrap: break-word;
}

.message-timestamp {
font-size: 0.85em;
color: #999;
text-align: right;
margin-top: 10px;
}

.loading-message {
  text-align: center;
  margin: 20px 0;
  font-family: 'Roboto', 'Arial', sans-serif; /* Match the App body font family if needed */
  color: #555; /* Match the App text color */
}
